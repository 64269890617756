import { memo as Memo } from 'react'

//* Components
import Text from 'components/common/Text'
import Image from 'components/common/Image'
import CustomLink from 'components/common/CustomLink'

//* Config
import { config } from 'helpers'

//* Styles
import NewsItemStyle from './style'

const NewsItem = Memo(({ title, slug, featured_image, date, id, categories }) => {
	return (
		<NewsItemStyle>
			<CustomLink
				className='ni-link'
				url={`${config.routes.news.path}/${slug}`}
			>
				<Image
					src={featured_image?.src}
					alt={featured_image?.alt}
				/>

				<div className='ni-info'>
					<Text className='ni-title h6 font-montserrat'>{title}</Text>

					<div className='ni-bottom'>
						<Text className='ni-date p p-2 font-montserrat'>{date}</Text>

						{categories && categories.length > 0 ? (
							<div className='ni-categories'>
								<Text
									tag='span'
									className='ni-cat p p-2 font-montserrat'
								>
									{categories[0].name}
								</Text>

								{categories.length - 1 > 0 ? (
									<Text
										tag='span'
										className='ni-cat p p-2 font-montserrat'
									>
										+{categories.length - 1}
									</Text>
								) : null}
							</div>
						) : null}
					</div>
				</div>
			</CustomLink>
		</NewsItemStyle>
	)
})

export default NewsItem
