import styled from 'styled-components'

const NewsItemStyle = styled.div`
    --niInfoPadding: var(--sp5x);
    --niTitleMarginBottom: var(--sp9x);
    --niCatPaddingH: var(--sp4x);
    --niCatPaddingV: var(--sp1-5x);

    height: 100%;

    .ni-link {
        height: 100%;
        display: flex;
        flex-direction: column;
        border-radius: var(--radiusM);
        overflow: hidden;

        .image-cont {
            --proportion: 65.9%;
        }

        .ni-info {
            height: 100%;
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: var(--niInfoPadding);
            background-color: var(--bgGray);
            transition: background var(--trTime);

            .ni-title {
                flex: 1;
                margin-bottom: var(--niTitleMarginBottom);
                color: var(--textBlack100);
                transition: color var(--trTime);
            }

            .ni-bottom {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .ni-date {
                    color: var(--textBlack100);
                    transition: color var(--trTime);
                }

                .ni-categories {
                    display: inline-flex;
                    align-items: center;
                    margin: calc(var(--niCatPadding) / -2);

                    .ni-cat {
                        padding: var(--niCatPaddingV) var(--niCatPaddingH);
                        border-radius: var(--radiusL);
                        background-color: var(--white);
                        margin: calc(var(--niCatPaddingV) / 2);
                    }
                }
            }
        }

        @media (hover: hover) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
            &:hover {
                .ni-info {
                    background-color: var(--red100);

                    .ni-title {
                        color: var(--white);
                    }

                    .ni-bottom {

                        .ni-date {
                            color: var(--white);
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
        --niInfoPadding: var(--sp4x);
        --niTitleMarginBottom: var(--sp7x);
        --niCatPaddingH: var(--sp3x);
        --niCatPaddingV: var(--sp1x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--niInfoPadding: var(--sp4x);
        --niTitleMarginBottom: var(--sp7x);
        --niCatPaddingH: var(--sp3x);
        --niCatPaddingV: var(--sp1x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
        --niInfoPadding: var(--sp4x);
        --niTitleMarginBottom: var(--sp5x);
        --niCatPaddingH: var(--sp3x);
        --niCatPaddingV: var(--sp1x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--niInfoPadding: var(--sp3x);
        --niTitleMarginBottom: var(--sp4x);
        --niCatPaddingH: var(--sp2x);
        --niCatPaddingV: var(--sp1x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--niInfoPadding: var(--sp3x);
        --niTitleMarginBottom: var(--sp4x);
        --niCatPaddingH: var(--sp2x);
        --niCatPaddingV: var(--sp1x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--niInfoPadding: var(--sp3x);
        --niTitleMarginBottom: var(--sp4x);
        --niCatPaddingH: var(--sp2x);
        --niCatPaddingV: var(--sp1x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--niInfoPadding: var(--sp3x);
        --niTitleMarginBottom: var(--sp3x);
        --niCatPaddingH: var(--sp2x);
        --niCatPaddingV: var(--sp1x);
    }
`

export default NewsItemStyle