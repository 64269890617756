import { memo as Memo, useMemo } from 'react'

//* HOCs
import { withUIContext } from 'context'

//* Components
import Container from 'components/common/Container'
import Text from 'components/common/Text'
import Button from 'components/common/Button'
import NewsItem from './NewsItem'

//* Config
import { config } from 'helpers'

//* Styles
import NewsSectionStyle from './style'

const NewsSection = Memo(({ title, items, winWidth, isListingPage, hasButton }) => {
	//! Item Cols
	const itemCols = useMemo(() => {
		const count = winWidth < 1279 ? 2 : 3
		const slicedItems = items.slice(0, count)

		if (isListingPage) {
			return items.map((i) => (
				<div
					key={i.id}
					className='ns-col col-4 col-t-6 col-m-12'
				>
					<NewsItem {...i} />
				</div>
			))
		} else {
			return slicedItems.map((i) => (
				<div
					key={i.id}
					className='ns-col col-4 col-t-6 col-m-12'
				>
					<NewsItem {...i} />
				</div>
			))
		}
	}, [winWidth, items])

	return (
		<NewsSectionStyle className={`news-section-container ${isListingPage ? 'listing' : ''}`}>
			<Container className='ns-cont'>
				<Container
					row
					full
				>
					<div className='col-6 col-m-12'>
						<Text
							tag='h2'
							className='ns-title h2 font-montserrat'
							text={title}
						/>
					</div>
				</Container>

				<Container
					row
					full
					className='ns-items-cont'
				>
					{itemCols}

					{hasButton && (
						<div className='ns-button-cont col-12'>
							<Button
								text='seeAll'
								className='ns-button'
								url={config.routes.news.path}
							/>
						</div>
					)}
				</Container>
			</Container>
		</NewsSectionStyle>
	)
})

export default withUIContext(NewsSection, ['winWidth'])
