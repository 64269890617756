import { memo as Memo, useMemo } from 'react'

//* HOC's
import withLanguageContext from 'context/consumerHOC/LanguageConsumer'

//* Component's
import Icon from 'components/common/Icon'
import CustomLink from 'components/common/CustomLink'

//* Style
import ButtonStyle from './style'

const Button = Memo(
	({ btnType, text, icon, className, onClick, children, url, customLinkProps, translate, target, type = 'button', backgroundColor, color, hoverBackgroundColor, hoverColor, isDisabled }) => {
		const Component = useMemo(() => (url ? CustomLink : 'button'), [url])
		const customProps = useMemo(() => (url ? { ...customLinkProps, url, target } : { type }), [url])

		return (
			<ButtonStyle
				backgroundColor={backgroundColor}
				color={color}
				hoverBackgroundColor={hoverBackgroundColor}
				hoverColor={hoverColor}
				className={`${btnType} ${className} ${isDisabled ? 'disabled' : ''}`}
				onClick={onClick}
			>
				<Component
					className='inner-text'
					{...customProps}
				>
					{children || translate(text)}
					<Icon
						className={'p p1 button-arrow-icon'}
						name={'button-arrow'}
					/>
				</Component>
			</ButtonStyle>
		)
	}
)

Button.defaultProps = {
	backgroundColor: 'green100',
	hoverBackgroundColor: 'red100',
	color: 'white',
	hoverColor: 'white',
	className: '',
}

export default withLanguageContext(Button, ['translate'])
