import styled from 'styled-components'

const ButtonStyle = styled.div`
	--buttonPadding: var(--sp3x) var(--sp5x);
	--buttonBorderRadius: var(--sp1-5x);
	--buttonIconMarginLeft: var(--sp5x);

	a,
	button {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding: var(--buttonPadding);
		background-color: ${(props) => `var(--${props.backgroundColor})`};
		color: ${(props) => `var(--${props.color})`};
		border-radius: var(--buttonBorderRadius);
		transition: var(--trTime);
		cursor: pointer;
	}

	&.disabled {
		pointer-events: none;

		a,
		button {
			background-color: var(--textBlack10);
			color: var(--textBlack60);

			.button-arrow-icon {
				color: var(--textBlack60);
			}
		}
	}

	@media (hover: hover) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		&:hover {
			a,
			button {
				background-color: ${(props) => `var(--${props.hoverBackgroundColor})`};
				color: ${(props) => (props.hoverColor ? `var(--${props.hoverColor})` : `var(--${props.color})`)};
			}
		}
	}

	.inner-text {
		font-size: var(--p1);
		font-family: var(--montserrat);
		font-weight: 500;
		cursor: pointer;
	}

	.button-arrow-icon {
		margin-left: var(--buttonIconMarginLeft);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--buttonPadding: var(--sp2-5x) var(--sp4x);
		--buttonBorderRadius: var(--sp1x);
		--buttonIconMarginLeft: var(--sp4x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--buttonPadding: var(--sp2-5x) var(--sp4x);
		--buttonBorderRadius: var(--sp1x);
		--buttonIconMarginLeft: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--buttonPadding: var(--sp2-5x) var(--sp4x);
		--buttonBorderRadius: var(--sp1x);
		--buttonIconMarginLeft: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--buttonPadding: var(--sp2x) var(--sp3x);
		--buttonBorderRadius: var(--sp1x);
		--buttonIconMarginLeft: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--buttonPadding: var(--sp1-5x) var(--sp3x);
		--buttonBorderRadius: var(--sp1x);
		--buttonIconMarginLeft: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--buttonPadding: var(--sp1-5x) var(--sp3x);
		--buttonBorderRadius: var(--sp1x);
		--buttonIconMarginLeft: var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		a,
		button {
			display: flex;
			justify-content: space-between;
		}

		--buttonPadding: var(--sp1-5x) var(--sp3x);
		--buttonBorderRadius: var(--sp1x);
		--buttonIconMarginLeft: var(--sp2x);
	}
`

export default ButtonStyle
