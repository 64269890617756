import styled from 'styled-components'

const NewsSectionStyle = styled.section`
	--nsItemsContPaddingTop: var(--distanceM2);
	--nsButtonPosition: absolute;
	--nsButtonInset: auto 0 100% auto;

	padding: var(--distanceL1) 0;

	&.listing {
		padding-top: var(--distanceM3);
        padding-bottom: unset;

		.ns-items-cont {
			padding-top: 0 !important;
		}
	}

	.ns-cont {
		position: relative;

		.ns-items-cont {
			align-items: stretch;
			position: relative;
			padding-top: var(--nsItemsContPaddingTop);
			margin-top: calc(-1 * var(--colPadding));
			margin-bottom: calc(-1 * var(--colPadding));

			.ns-col {
				padding-top: var(--colPadding);
				padding-bottom: var(--colPadding);
			}

			.ns-button {
				position: var(--nsButtonPosition);
				inset: var(--nsButtonInset);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--nsButtonPosition: relative;
		--nsButtonInset: auto;

		.ns-button {
			margin-top: var(--sp5x);
		}
	}
`

export default NewsSectionStyle
